module.exports = [{
      plugin: require('/opt/build/repo/web/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Jorge Masta (Developer)","short_name":"Jorge Masta","start_url":"/","background_color":"#ffffff","theme_color":"#2E52A3","display":"standalone","icon":"src/images/favicon.png","theme_color_in_head":false},
    },{
      plugin: require('/opt/build/repo/web/node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://jorgemasta.com"},
    },{
      plugin: require('/opt/build/repo/web/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
